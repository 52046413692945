import logo from './logo.svg';
import './App.css';
import { useState } from 'react';
import { Tldraw } from 'tldraw';
import { useSyncDemo } from '@tldraw/sync';
import 'tldraw/tldraw.css';

function App() {
  const store = useSyncDemo({ roomId: 'monkeydemotest' });
  return (
    <div style={{position: 'fixed', inset: 0}}>
      <Tldraw store={store} />
    </div>
  );
}

export default App;
